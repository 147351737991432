import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import Card from "clutch/src/Card/Card.js";

import { HeaderIcon } from "@/game-cs2-marketing/CommonComponents";
import { MAPS } from "@/game-cs2-marketing/constants.mjs";
import DatabaseMapStats from "@/game-cs2-marketing/DatabaseMapStats.jsx";
// import DatabaseMapStatsTrends from "@/game-cs2-marketing/DatabaseMapStatsTrends";
import MAP_OVERVIEWS from "@/game-cs2-marketing/map-overviews/overviews.mjs";
import MAP_UPDATES from "@/game-cs2-marketing/map-updates/updates.mjs";
import MapLayout from "@/game-cs2-marketing/MapLayout";
import { getMapEmblem, getMapImage } from "@/game-cs2-marketing/utils.mjs";
import CaretDown from "@/inline-assets/blitz-caret-down.svg";
import CaretUp from "@/inline-assets/caret-up.svg";
import Container from "@/shared/ContentContainer.jsx";
import { classNames } from "@/util/class-names.mjs";
import { formatDateTime, getLocale } from "@/util/i18n-helper.mjs";

const MainColumnsContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);

  > * {
    display: grid;
    grid-template-rows: max-content;
    gap: var(--sp-4);
  }

  .span-2 {
    grid-column: span 2;
    height: fit-content;
  }

  color: var(--shade1);
  /* TODO: Replace this with common font type */
  font-size: var(--sp-3_5e);
  line-height: var(--sp-6e);

  h4 {
    color: var(--shade0);
  }

  > ul li {
    color: var(--shade1);

    strong {
      color: var(--shade0);
    }
  }

  ul {
    list-style: inside;
    padding-left: var(--sp-1);
  }

  .images {
    display: flex;
    flex-direction: row;
    gap: var(--sp-4);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;
    img {
      border-radius: var(--br);
      height: auto;
      width: calc(7 * var(--sp-10));
    }
  }

  @container content-container (width <= 700px) {
    grid-template-columns: initial;
    .span-2,
    .span-1 {
      grid-column: initial;
    }
    .span-1 {
      grid-row: 1;
    }
  }
`;

const UpdatesCard = styled(Card)`
  strong {
    color: #f3a722;
  }

  .card-contents {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    overflow: hidden;
  }
`;

const OverviewCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);

  strong {
    color: var(--shade0);
  }
`;

const GeneralCard = styled(Card)`
  display: flex;
  flex-flow: column;
  row-gap: var(--sp-2);
  .row {
    display: flex;
    p:first-child {
      width: 50%;
    }

    p:nth-child(2) {
      color: var(--shade0);
      width: 50%;
      text-align: right;
      &.highlight {
        color: #f3a722;
      }
    }
  }
`;

const MapCard = styled(Card)`
  overflow: hidden;
`;

const MapWithEmblem = styled("div")`
  display: flex;
  position: relative;

  .icon {
    opacity: 0.25;
  }

  .emblem {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export type MapKey = {
  key: string;
  highlight: boolean;
  label: string[];
  valueFn: (value: string | string[] | boolean) => string;
};

const MAP_KEYS: MapKey[] = [
  {
    key: "scenario",
    highlight: false,
    label: ["cs2:database.maps.scenario", "Scenario"],
    valueFn: (value: string) => value,
  },
  {
    key: "gameModes",
    highlight: false,
    label: ["cs2:database.maps.gameModes", "Game Modes"],
    valueFn: (value: string[]) => value.join(" / "),
  },
  {
    key: "activeDuty",
    highlight: false,
    label: ["cs2:database.maps.activeDuty", "Active Duty"],
    valueFn: (value: boolean) => (value ? "Yes" : "No"),
  },
  {
    key: "releasedOn",
    highlight: false,
    label: ["cs2:database.maps.released", "Released"],
    valueFn: (value: string) =>
      formatDateTime(getLocale(), new Date(value), {
        month: "short",
        year: "numeric",
        day: "numeric",
      }),
  },
  {
    key: "lastUpdated",
    highlight: true,
    label: ["cs2:database.maps.lastUpdated", "Last Update"],
    valueFn: (value: string) =>
      formatDateTime(getLocale(), new Date(value), {
        month: "short",
        year: "numeric",
        day: "numeric",
      }),
  },
  {
    key: "terrorists",
    highlight: false,
    label: ["cs2:database.maps.terrorists", "Terrorists"],
    valueFn: (value: string) => value,
  },
  {
    key: "counterTerrorists",
    highlight: false,
    label: ["cs2:database.maps.counterTerrorists", "Counter-Terrorists"],
    valueFn: (value: string) => value,
  },
  {
    key: "location",
    highlight: false,
    label: ["cs2:database.maps.location", "Location"],
    valueFn: (value: string) => value,
  },
  {
    key: "creators",
    highlight: true,
    label: ["cs2:database.maps.creators", "Creator(s)"],
    valueFn: (value: string[]) => value.join(" / "),
  },
];

function DatabaseMapOverview({ map }) {
  const { t } = useTranslation();
  const [showingMore, setShowingMore] = useState(false);
  const Overview = MAP_OVERVIEWS[map];
  const Updates = MAP_UPDATES[map];

  const rows = MAP_KEYS.map((mapKey) => {
    const value: string | string[] | boolean = MAPS[map][mapKey.key];
    return (
      <div key={mapKey.key} className="row">
        <p className="type-callout--semi">
          {t(...(mapKey.label as [string, string]))}
        </p>
        <p
          className={
            classNames(
              "type-callout--semi",
              mapKey.highlight ? "highlight" : "",
            ).className
          }
        >
          {mapKey.valueFn(value)}
        </p>
      </div>
    );
  });

  return (
    <Container>
      <MainColumnsContainer>
        <div className="span-2">
          <MapCard classNameOuter="span-2">
            <MapLayout map={map} />
          </MapCard>
          {/* <DatabaseMapStatsTrends map={map} /> */}
          {Overview ? (
            <OverviewCard classNameOuter="span-2">
              <h4>{t("cs2:database.maps.overview", "Overview")}</h4>
              <Overview />
            </OverviewCard>
          ) : null}
          <Card padding="0" classNameOuter="span-2">
            <DatabaseMapStats />
          </Card>
          {Updates ? (
            <UpdatesCard
              classNameOuter="span-2"
              footerButtonText={
                showingMore
                  ? t("common:showLess", "Show less")
                  : t("common:showMore", "Show more")
              }
              footerButtonIcon={showingMore ? <CaretUp /> : <CaretDown />}
              footerButtonFn={() => {
                setShowingMore((prev) => !prev);
              }}
              style={{
                maxHeight: showingMore
                  ? "fit-content"
                  : "calc(var(--sp-10) * 10)",
              }}
            >
              <h4>{t("cs2:database.updateHistory", "Update History")}</h4>
              <Updates />
            </UpdatesCard>
          ) : null}
        </div>
        <div className="span-1">
          <GeneralCard classNameOuter="span-1">
            <MapWithEmblem>
              <HeaderIcon
                className="icon"
                src={getMapImage(map)}
                $width="100%"
                $height="calc(10 * var(--sp-3_5))"
              />
              <HeaderIcon
                className="emblem"
                src={getMapEmblem(map)}
                $width="var(--sp-20)"
                $height="var(--sp-20)"
              />
            </MapWithEmblem>
            <h4>{t("cs2:database.maps.general", "General")}</h4>
            {rows}
          </GeneralCard>
        </div>
      </MainColumnsContainer>
    </Container>
  );
}

export default DatabaseMapOverview;
